<template>
    <el-submenu
            v-if="menu.hasSub && menu.subMenu.length > 0"
            :index="menu.index">
        <template slot="title">
            <i :class="menu.icon || ''"></i>
            <span>{{ menu.title }}</span>
        </template>
        <manage-sub-menu
                v-for="(item, i) in menu.subMenu"
                :key="menu.index + i"
                :menu="item">
        </manage-sub-menu>
    </el-submenu>
    <el-menu-item v-else-if="!menu.hasSub" :index="menu.index" @click="menuClick(menu)">
        <i :class="menu.icon || ''"></i>
        <span>{{ menu.title }}</span>
    </el-menu-item>
</template>

<script>
export default {
    name: 'manage-sub-menu',
    props: {
        menu: {
            type: Object,
            required: true
        }
    },
    methods: {
        // 菜单项点击处理
        menuClick(item) {
            if (item.blank) {
              let r = this.$router.resolve({name: item.index})
              window.open(r.href, '_blank')
            }else {
                this.$router.push({name: item.index})
            }
        }
    }
}
</script>

<style scoped>

</style>
