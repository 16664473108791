<template>
  <el-main class="white-box" style="margin-left: 20px;margin-right: 20px">
    <router-view></router-view>
  </el-main>
</template>

<script>
  export default {
    name: 'manage-content'
  }
</script>

<style scoped>
  .el-main {
    /*height: calc(100vh - 170px);*/
    /*overflow-y: hidden;*/
  }
  .el-main >>> .el-scrollbar__view {
    height: 100%;
  }
  .white-box {
    background: #ffffff;
    font-size: 14px;
  }
  .manage-wrap >>> .el-scrollbar__wrap {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }

  .manage-wrap >>> .el-menu {
    border: none;
  }

  .manage-wrap >>> .el-aside .el-scrollbar__view {
    border: 1px solid #e6e6e6;
  }
  .manage-wrap >>> .el-main .el-scrollbar__view {
    height: 100%;
  }
  .manage-wrap >>> .el-scrollbar__bar.is-vertical {
    width: 0;
  }

  .manage-wrap >>> .el-scrollbar__bar.is-horizontal {
    height: 0;
  }
</style>
